import GlobalLink from 'components/GlobalLink';
import Img from 'components/Img';
import Typography from 'components/Typography';
import { FC } from 'react';
import { Martian } from 'state/reducers/lands';

import { replaceAssetsUrl } from '../utils';
import Button from './Button';

type RequiredProps = {
	martian: Martian,
	extraInfo: string
}

type OptionalProps = { 
	selectedTag?: { tag: string, tid: string },
	download?: string
	actions?: JSX.Element
  className?: string
	onWithdrawClicked?: (object) => void
}

export const AddMartianCard = ({ className, actions }) => {
	return (
		<article
			key="add-martian"
			className={`relative w-full border-2 rounded-lg overflow-hidden "border-white"
			} ${className}`}
		>
			<div
				className="bg-contain pt-20"
			>
				<Img
					className="p-4"
					src="/static/images/battlefield/add_martian.png"
					width={200}
					height={200}
					alt=""
				/>
			</div>
				<div className="mt-10 p-4 mt-6 mb-2 text-center">
					{actions && {...actions}}
				</div>
		</article>
	);
}

export const NoMartianCard = ({ title, className }) => {
	return (
		<article
			key="no-martian"
			className={`relative w-full border-2 rounded-lg overflow-hidden "border-white"
			} ${className}`}
		>
			<Typography variant="h4" className="text-center p-8">{title}</Typography>
			<div
				className="bg-contain"
			>
				<Img
					className="p-4"
					src="/static/images/battlefield/add_martian.png"
					width={200}
					height={200}
					alt=""
				/>
			</div>
			<div className="w-2/3 mx-auto p-4">
				<Button className="w-full mx-auto" onClick={() => document.location.href = 'https://martians.marsgenesis.com'}>Mint one</Button>
			</div>
		</article>
	);
}

export const BattlefieldMartianCard: FC<RequiredProps & OptionalProps> =({
	martian,
	extraInfo = "",
	selectedTag = { tag: "", tid: "" },
	download,
	actions,
  className,
	onWithdrawClicked
}) => {
	return (
		<article
			key={martian?.id}
			className={`relative w-full border-2 rounded-lg overflow-hidden ${
				martian?.is_golden
					? "border-yellow-400 bg-yellow-400 bg-opacity-10"
					: "border-white"
			} ${className}`}
		>
			<div className="absolute top-4 left-4 text-sm text-yellow-400">{martian.rarity.percentile}%</div>
			{onWithdrawClicked && (<div className="absolute top-4 right-4">
				<Img className="cursor-pointer" src="/static/images/battlefield/withdraw_icon.png" alt="Withdraw" width={24} height={24} onClick={() => onWithdrawClicked(martian)} />
			</div>)}
			<header
				className="bg-contain pt-4"
			>
				<Img
					className="p-4"
					src={replaceAssetsUrl(martian?.assets?.small?.face_url)}
					width={180}
					height={180}
					alt=""
				/>
			</header>
			<div
				className="p-4"
			>
				<header className="flex flex-col justify-between">
					<div className="text-center">
						{actions ? 
							<Typography
								variant="h2"
								component="a"
								className="text-white w-full text-center"
							>
							#{martian?.id}
						</Typography> :
              <GlobalLink>
                <Typography
                  variant="h3"
                  component="span"
                  className="text-white font-bold"
                >
                  #{martian?.id}
                </Typography>
              </GlobalLink>
						}
					</div>
					<div className="mt-6 text-xl text-white text-center">
						{martian?.currency?.symbol}
					</div>
					<div className="mt-0 text-md text-gray-400 text-center">
						{martian?.currency?.name}
					</div>
				</header>
				<footer className="mb-2 text-center">
					{actions && {...actions}}
				</footer>
			</div>
		</article>
	);
}

export default BattlefieldMartianCard;