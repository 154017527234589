import Button from 'components/Button';
import { useRouter } from 'next/router';

import { splitChunks } from '../../../utils';
import { toQueryString } from '../../../utils';
import BattlefieldMartianCard, { AddMartianCard } from '../../BattlefieldMartianCard';
import Img from '../../Img';
import Typography from '../../Typography';

const SelectMartian = ({
	fromAcceptChallenge = false,
	martians,
	onMartianSelected,
	onWithdrawMartian,
	onDisplayBattleHistory,
}) => {
	const router = useRouter();
	const cards = Object.keys(martians || {});
	cards.push("000"); // fake ID for the "add martian" card... hacky
	const divider = 3;
	const chunks = splitChunks(cards, divider); // max 5 per row?

	return (
		<div className="flex flex-col w-full h-full">
			{fromAcceptChallenge ? (
				<Typography variant="h1" className="mt-8 mx-auto text-center md:mt-12">
					Who will fight this challenge?
				</Typography>
			) : (
				<>
					<Typography
						variant="h1"
						className="mt-8 mx-auto text-center md:mt-12"
					>
						The Battlefield
					</Typography>
					<Typography
						variant="h4"
						className="relative mt-2 mx-auto w-auto text-center cursor-pointer"
						onClick={onDisplayBattleHistory}
					>
						<Img
							alt="history"
							src="/static/images/battlefield/history.png"
							width={20}
							height={20}
							className="absolute"
							style={{ left: "-28px", top: "5px" }}
						/>{" "}
						previous battles
					</Typography>
				</>
			)}

			{chunks.map((chunk) => (
				<div className="flex flex-col justify-center m-auto  md:mt-12 md:pb-60 md:pt-10 w-4/5 md:w-full lg:flex-row lg:pb-0 lg:pt-0">
					{chunk.map((id) =>
						id !== "000" ? (
							<BattlefieldMartianCard
								className="mx-auto my-4 max-w-2xl bg-black bg-opacity-80 rounded-2xl backdrop-blur-md backdrop-filter lg:mx-4 lg:my-0 lg:max-w-xs"
								martian={(martians || {})[id]}
								key={`martian-${id}`}
								extraInfo=""
								onWithdrawClicked={onWithdrawMartian}
								actions={
									<Button
										className="small mt-4 px-8 py-2 w-2/3 text-white hover:bg-opacity-90 border-2 rounded-full uppercase"
										onClick={() => onMartianSelected((martians || {})[id])}
										ghost={true}
										small={true}
									>
										Pick to battle
									</Button>
								}
							/>
						) : (
							<AddMartianCard
								className="mx-auto my-4 max-w-2xl bg-black bg-opacity-80 rounded-2xl backdrop-blur-md backdrop-filter lg:mx-6 lg:my-0 lg:max-w-xs"
								actions={
									<Button
										className="small px-8 py-2 w-2/3 text-white hover:bg-opacity-90 border-2 rounded-full uppercase"
										onClick={() => {
											const qs = toQueryString(router.query)
								router.push("/battlefield/add?forceNext=true&" + qs);
										}}
										ghost={true}
										small={true}
									>
										Add martian
									</Button>
								}
							/>
						)
					)}
				</div>
			))}
		</div>
	);
};

export default SelectMartian;
