import { FC, FunctionComponent, MouseEventHandler } from 'react';

import Button from './Button';
import Img from './Img';
import Typography from './Typography';

type RequiredProps = {
  title: string;
  heading: string | JSX.Element;
}

type OptionalProps = {
  icon?: string;
  className?: string;
}

type SubmitButtonRequiredProps = {
  label: string | JSX.Element;
  width?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
  outline?: boolean;
  small?: boolean;
}

export const SubmitButton: FC<SubmitButtonRequiredProps> = ({ label, onClick = () => {}, disabled = false, className, width = 'lg:w-full', outline = false, small = false }) => 
  <Button className={`
      ${width}
      my-10
      py-4 
      border-2 
      hover:bg-opacity-90 
      rounded-full 
       
      uppercase
      tracking-widest ${className}`}
      onClick={onClick} 
      disabled={disabled}
      ghost={outline}
      small={small}
    >
    {label}
  </Button>

export const ContentWrapper: FC<RequiredProps & OptionalProps> = ({ title, heading, icon, className, children }) => {
  return (
    <div className={"container h-full mt-0 " + className}>
      <div className="relative rounded-3xl bg-black bg-opacity-80 mx-auto mt-20 pt-6 justify-center flex flex-col lg:w-1/2 w-11/12 backdrop-blur-md backdrop-filter">
        <Typography
          variant="h1"
          className="lg:mt-8 mt-6 text-center text-white "
          style={{
            fontWeight: '300',
            lineHeight: '40px',
            letterSpacing: '0.5px'
          }}
        >
          {title}
        </Typography>
        <Typography
            variant="h5"
            className="mt-4 lg:px-20 px-4 text-center text-white"
            style={{
              fontWeight: '200',
              lineHeight: '26px',
              letterSpacing: '0.5px'
            }}
          >
            {heading}
          </Typography>
          {icon && 
            <Img
              alt="martian waiting"
              width={200}
              height={200}
              className="flex flex-shrink-0 mt-8"
              src={`/static/images/battlefield/${icon}.png`}
            />
          }
          {children}
      </div>
    </div>
  )
}
