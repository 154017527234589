type node = keyof JSX.IntrinsicElements;

const defaultVariantMapping: { [key: string]: node } = {
	bigTitle: "h1",
	h1: "h1",
	h2: "h2",
	h3: "h3",
	h4: "h4",
	h5: "h5",
	h6: "h6",
	subtitle1: "h6",
	subtitle2: "h6",
	p: "p",
	sp: "p",
};

const defaultStyle = {
	bigTitle:
		"text-4xl sm:text-6xl lg:text-7xl leading-none tracking-tight font-black text-white",
	h1: "text-3xl sm:text-4xl leading-none font-semibold text-white",
	h2: "text-3xl font-semibold",
	h3: "text-xl sm:text-2xl font-semibold",
	h4: "text-xl",
	h5: "text-base",
	h6: "text-sm",
	p: "text-lg sm:text-xl sm:leading-10",
	sp: "text-base sm:text-lg sm:leading-6",
};

export default function Typography({
	className,
	component,
	variant = "p",
	children,
	style = {},
	...rest
}: {
	className?: string;
	component?: node;
	variant?: keyof typeof defaultVariantMapping;
	children: any;
	style?: { [key: string]: string };
	onClick?: any;
}) {
	const Component = component || defaultVariantMapping[variant] || "p";
	const classes =
		(defaultStyle[variant] || "") + (className ? " " + className : "");

	return (
		<Component className={classes} style={{ ...style }} {...rest}>
			{children}
		</Component>
	);
}
