import Snack from 'components/Snack';
import Head from 'next/head';
import Script from 'next/script';
import { ToastProvider } from 'react-toast-notifications';
import StateProvider from 'state';

import BattlefieldHeader from './BattlefieldHeader';

const BattlefieldLayout = ({ Component, pageProps }) => {
	return (
		<>
			<StateProvider>
				<Head>
					<title>Battle of Mars</title>
					<link
						rel="apple-touch-icon"
						sizes="180x180"
						href="/apple-touch-icon.png"
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="32x32"
						href="/favicon-32x32.png"
					/>
					<link
						rel="icon"
						type="image/png"
						sizes="16x16"
						href="/favicon-16x16.png"
					/>
					<link rel="manifest" href="/site.webmanifest" />
					<link
						href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800"
						rel="stylesheet"
					/>
					<link rel="preload" as="image" href="/static/images/backgrounds/1.png" />
					<link rel="preload" as="image" href="/static/images/backgrounds/2.png" />
					<link rel="preload" as="image" href="/static/images/backgrounds/3.png" />
					<link rel="preload" as="image" href="/static/images/backgrounds/4.png" />
					<link rel="preload" as="image" href="/static/images/backgrounds/5.png" />
					<link rel="preload" as="image" href="/static/images/backgrounds/6.png" />
					<link rel="preload" as="image" href="/static/images/backgrounds/7.png" />
					<link rel="preload" as="image" href="/static/images/backgrounds/8.png" />
					<link rel="preload" as="image" href="/static/images/backgrounds/9.png" />
					<link rel="preload" as="image" href="/static/images/backgrounds/10.png" />
				</Head>
				<Script
					id="model-viewer"
					src="https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js"
					strategy="lazyOnload"
					type="module"
				/>
				<ToastProvider placement="bottom-center" components={{ Toast: Snack }}>
					<div className="min-h-screen">
						<BattlefieldHeader />
						<Component {...pageProps} />
						{/* <Footer /> */}
					</div>
				</ToastProvider>
			</StateProvider>
			<div id="Modal" />
		</>
	);
}

export default BattlefieldLayout; 