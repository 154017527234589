import { forwardRef, useEffect, useState } from "react";

export default forwardRef(function Link({ children, ...props }: any, _) {
	const [selecting, setselecting] = useState(false);

	useEffect(() => {
		window.addEventListener("keydown", enableSelection);
		window.addEventListener("keyup", disableSelection);
		return () => {
			window.removeEventListener("keydown", enableSelection);
			window.removeEventListener("keyup", disableSelection);
		};
		function enableSelection(event) {
			if (event.altKey) {
				setselecting(true);
			}
		}
		function disableSelection() {
			setselecting((selecting) => (selecting ? !selecting : selecting));
		}
	}, []);

	return (
		<a
			className={`global-link ${
				selecting ? "pointer-events-none" : "pointer-events-auto"
			}`}
			{...props}
		>
			{children}
		</a>
	);
});
