import { ContentWrapper } from 'components';
import Typography from 'components/Typography';
import { FC } from 'react';

type AccountVerified = { email: string }

export const AccountVerifiedDialog: FC<AccountVerified> = ({ email }) => 
  <ContentWrapper title="Thank you!" heading={
      <div>For verifying your email<br />{email}</div>
    } icon="verifiedEmailIcon">
    <Typography
      variant="h4"
      className="lg:px-44 lg:pt-12 pt-6 pb-10 text-yellow-300"
      style={{
        fontWeight: '200',
        lineHeight: '30px',
        letterSpacing: '2.5px'
      }}
    >
    </Typography>
  </ContentWrapper>