import { ContentWrapper, SubmitButton } from 'components';
import Loader from 'components/Loader';
import Typography from 'components/Typography';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { addUserName, useGlobalState } from 'state';

type UsernameDialogProps = {
  address: string | undefined;
}

export const UsernameDialog: FC<UsernameDialogProps> = ({ address  }) => {
  const [ _, dispatch] = useGlobalState();
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);
  const [error, setError] = useState("");

  const handleFormSubmit = async ({ username }) => {
    const regex = new RegExp('^[0-9a-zA-Z]+$');
    if (!regex.test(username)) {
      setError('Your username can\'t have any spaces or special characters!');
      return;
    }

    if (address) {
      addUserName(address, username)
        .then(dispatch)
        .then(() => {
          setLoading(false);
        })
        .catch(error => {
          setError(error.toString());
          setLoading(false);
        })
    }
    setLoading(true);
  }

  return (
    <ContentWrapper
      title="Username"
      heading="What's the name that will bring glory and fortune to your family for centuries?"
    >
      <form className="flex flex-col mt-10 md:mx-auto md:w-3/4" onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="relative block text-center lg:text-lg text-base tracking-wider lg:mx-20 mx-6">
          <label className="font-bold">
            Username
            <input 
              type="text"
              autoFocus={true}
              // maxLength={10}
              className="block my-2 w-full text-blueGray-900 bg-white focus:bg-white focus:border-gray-500 border-transparent rounded-full focus:ring-0 lg:py-4 py-4 text-center"
              placeholder="e.g ZorgDaDestructor"
              required
              {...register("username")}
            />
          </label>
          {error && 
            <Typography variant="sp" className="ml-2 mt-2 text-center text-red-200">
              {error}
            </Typography>
          }
          <SubmitButton small width='w-full' label={loading ? <Loader className="block" /> : "Next"} disabled={success}/>
        </div>
      </form>
    </ContentWrapper>
  )
}
