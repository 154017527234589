import Image from "next/image";

export default function Img({
	src,
	alt,
	width,
	height,
	className = "",
	style = {},
	...props
}) { 
	return (
		<figure
			className={`mx-auto w-full ${className}`}
			style={{ maxWidth: width, ...style }}
			key={src}
		>
			<picture
				className="relative block w-full h-0 overflow-hidden"
				style={{
					paddingBottom: (height / width) * 100 + "%",
				}}
				{...props}
			>
				{src ? (
					<Image
						loader={({ src, width }) => {
							return process.env.NEXT_PUBLIC_STAGE !== "staging"
								? src
										.replace("https://assets.marsgenesis.com", "/s3")
										.replace("https://cards.marsgenesis.com", "/s3/cards") +
										`?width=${width}`
								: src + `?width=${width}`;
						}}
						className="absolute inset-0"
						width={width}
						height={height}
						alt={alt}
						src={src}
					/>
				) : null}
			</picture>
			<style jsx>{`
				picture,
				img {
					border-radius: inherit;
				}
			`}</style>
		</figure>
	);
}
