import Typography from 'components/Typography';
import useCountdown from 'hooks/useCountdown';

import { ContentWrapper } from '../../ContentWrapper';
import Img from '../../Img';
import GameRules from './GameRules';

const WaitingChallenger = ({ challengerUsername, remainingTime, onCancel }) => {
	const { display, countdown } = useCountdown(remainingTime);

	return (
		<div className="flex-col m-auto w-full">
			<ContentWrapper
				className="mx-auto pb-10"
				title="Waiting for"
				heading={challengerUsername}
			>
				<Img
					alt="martian waiting"
					width={150}
					height={150}
					className="flex flex-shrink-0 mt-4"
					src={`/static/images/matching.gif`}
				/>
				<Typography variant="bigTitle" className="mt-10 text-center md:mt-2">
					{display}
				</Typography>
				<p className="text-md my-8 px-8 text-center text-gray-400">
					We sent {challengerUsername} an email with a unique link to accept the
					battle.<br />Let’s hope they check their inbox or their spam forlder.
				</p>
				{/* <a
					href="#"
					className="mx-auto my-10 text-yellow-400"
					onClick={onCancel}
				>
					Cancel battle
				</a> */}
			</ContentWrapper>
			<GameRules />
		</div>
	);
};

export default WaitingChallenger;
