import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';
import { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useGlobalState, UserType } from 'state';

import useInterval from '../../hooks/useInterval';
import useWeb3 from '../../hooks/useWeb3';
import { types } from '../../state';
import {
  acceptChallenge,
  cancelRandomBattle,
  fetchRandomBattleWaitResult,
  getMartiansInField,
  notifyTopupTransaction,
  registerForRandomBattle,
  sendChallenge,
  withdrawMartianFromBattlefield,
} from '../../state/actions/battlefield';
import { Martian } from '../../state/reducers/lands';
import ConfirmMartianSelection from './battle/ConfirmMartianSelection';
import MatchingMartian from './battle/MatchingRandomMartian';
import { SelectChallenger } from './battle/SelectChallenger';
import SelectMartian from './battle/SelectMartian';
import WaitingChallenger from './battle/WaitingChallenger';
import WithdrawMartianModal from './battle/WithdrawMartianModal';

type RequiredProps = {
	user: UserType;
};

type OptionalProps = {
	fromAcceptChallenge: boolean;
};

export const BattlefieldFlow: FC<RequiredProps & OptionalProps> = ({
	user,
	fromAcceptChallenge,
}) => {
	const { nextStep, address } = user;
	if (!address) return null;

	const [{ web3, battlefield }, dispatch] = useGlobalState();
	const { topupBattlefieldWithdrawBalance } = useWeb3({});
	const [selectedMartian, setSelectedMartian] = useState<Martian>();
	const [currentlyMatching, setCurrentlyMatching] = useState<boolean>(false);
	const [currentlyChoosingChallenger, setCurrentlyChoosingChallenger] =
		useState(false);
	const [selectedMartianForWithdrawal, setSelectedMartianForWithdrawal] =
		useState<Martian>();
	const [withdrawalProcessStarted, setWithdrawalProcessStarted] = useState(false);
	const [challengerUsername, setChallengerUsername] = useState();

	const router = useRouter();
	const { addToast } = useToasts();

	const setBackground = (background) =>
		dispatch({ type: types.UPDATE_USER, payload: { background: "6" } });

	const martians = battlefield.martians;
	const currentBattle = battlefield.currentBattle;

	useEffect(() => {
		getMartiansInField().then(dispatch);
	}, []);

	// random match result polling
	useInterval(
		() => {
			fetchRandomBattleWaitResult(battlefield.martianIdBeingMatched)
				.then(dispatch);
		},
		// delay or stop it
		battlefield.isBeingMatchedWithPlayer && battlefield.martianIdBeingMatched
			? 1000
			: null
	);

	// Handle case if the register for random battle
	// immediately returns a battle info
	// (not really clean, @TODO improve this)
	useEffect(() => {
		if (currentlyMatching && battlefield.currentBattle) {
			setTimeout(() => {
				router.push("/battle/" + battlefield.currentBattle?.id);
			}, 4000);
		}
	}, [battlefield.currentBattle, currentlyMatching]);

	const onConfirmBattle = () => {
		if (battlefield.currentBattle) {
			router.push("/battle/" + battlefield.currentBattle.id);
		}
	};

	const displayBattleHistory = () => {
		router.push("/history");
	};

	const handleWithdrawMartian = (martian) => {
		// TODO
		console.log("withdraw ", martian);
		setSelectedMartianForWithdrawal(martian);
	};

	const cancelWithdrawal = () => {
		setSelectedMartianForWithdrawal(undefined);
	};

	const confirmMartianForRandomMatch = (martianId) => {
		setCurrentlyMatching(true);

		registerForRandomBattle(martianId)
			.then(dispatch)
			.catch((error) => {
				console.log(error);
			});
	};

	const confirmMartianForChallengerSelection = (martianId) => {
		setCurrentlyChoosingChallenger(true);
	};

	const handleCancelRandomMatch = () => {
		if (!selectedMartian) {
			return;
		}
		
		// dispatch({
		// 	type: types.battlefield.REGISTER_RANDOM_BATTLE_CANCEL,
		// 	payload: {},
		// });
		cancelRandomBattle(selectedMartian.id)
			.then(dispatch)
			.then(() => {
				setCurrentlyMatching(false)
				setSelectedMartian(undefined)
			})
			.catch((error) => {
				console.log(error);
				addToast(error.message, {
					appearance: "error",
				});
			});
	};

	const confirmWithdrawal = () => {
		if (!selectedMartianForWithdrawal) {
			return;
		}

		const onFeesBalanceNeeded = (feesBalanceNeeded) => {
			setWithdrawalProcessStarted(true);
			console.log(feesBalanceNeeded);

			topupBattlefieldWithdrawBalance({
				ethValue: feesBalanceNeeded,
				onConfirmation: () => {
					// retry the endpoint now?
					// we likely need to wait for the transfer to be done.
					addToast(
						"Transaction successful",
						{
							appearance: "success",
						}
					);

					// ...and recall the endpoint again
					setTimeout(() => {
						setWithdrawalProcessStarted(false);
						withdrawMartianFromBattlefield(
							selectedMartianForWithdrawal,
							() => {
								addToast("Please retry withdrawing in a few moments", { appearence: "error" })
							}
						)
							.then(dispatch)
							.then(() => setSelectedMartianForWithdrawal(undefined))
							.then(() =>
								addToast("Your martian has been withdrawn. It might take a few moments for it to appear.", { appearence: "success" })
							)
					}, 4000);
				},
				onTransactionHashReady: (hash) => {
					notifyTopupTransaction(hash)
						.catch((error) => {
							// handle error
							// but maybe we don't need to display it
						});
				},
				onError: () => {},
			});
		};

		withdrawMartianFromBattlefield(
			selectedMartianForWithdrawal,
			onFeesBalanceNeeded
		)
			.then(dispatch)
			.then(() => setSelectedMartianForWithdrawal(undefined))
			.then(() =>
				addToast("Your martian has been withdrawn. It might take a few moments for it to appear.", { appearence: "success" })
			)
			.catch((error) => {
				console.log(error);
				addToast(error.message, {
					appearance: "error",
				});
			});
	};

	const doSendChallenge = (username, isFriendly) => {
		if (!selectedMartian) {
			return;
		}

		sendChallenge(selectedMartian.id, username, isFriendly)
			.then(dispatch)
			.then(() => setChallengerUsername(username))
			.catch((error) => {
				console.log(error);
				addToast(error.message, {
					appearance: "error",
				});
			});
	};

	// This is shown ONLY when the challenged_martian param
	// is present in query params, so the behaviour
	// is different (ideally we'd probably need a different component for this screen)
	if (fromAcceptChallenge) {
		return (
			<SelectMartian
				fromAcceptChallenge
				martians={martians}
				onMartianSelected={(martian) => {
					acceptChallenge(martian.id, (battleId) => {
						router.push("/battle/" + battleId);
					})
						.then(dispatch)
						.catch((error) => {
							console.log(error);
							addToast(error.message, {
								appearance: "error",
							});
						});
				}}
				onDisplayBattleHistory={() => {}}
				onWithdrawMartian={undefined}
			/>
		);
	}

	if (challengerUsername) {
		return (
			<WaitingChallenger
				challengerUsername={challengerUsername}
				remainingTime={3600}
				onCancel={() => {
					// cancel endpoint??
					setChallengerUsername(undefined);
					setCurrentlyChoosingChallenger(false);
				}}
			/>
		);
	}

	if (currentlyChoosingChallenger) {
		return (
			<SelectChallenger
				onSelectChallengerUsername={(username, isFriendly) => doSendChallenge(username, isFriendly)}
			/>
		);
	}

	if (currentlyMatching) {
		return (
			<MatchingMartian
				currentUser={user}
				onCancel={handleCancelRandomMatch}
				battle={battlefield.currentBattle}
				onConfirmBattle={onConfirmBattle}
			/>
		);
	}

	if (selectedMartian) {
		return (
			<ConfirmMartianSelection
				selectedMartian={selectedMartian}
				onConfirm={() => confirmMartianForRandomMatch(selectedMartian.id)}
				onConfirmChallengeFriend={() =>
					confirmMartianForChallengerSelection(selectedMartian.id)
				}
			/>
		);
	}

	return (
		<>
			<SelectMartian
				martians={martians}
				onMartianSelected={(martian) => {
					window.scrollTo(0, 0);
					setSelectedMartian(martian);
					setBackground("6");
				}}
				onDisplayBattleHistory={displayBattleHistory}
				onWithdrawMartian={handleWithdrawMartian}
			/>
			{selectedMartianForWithdrawal && (
				<WithdrawMartianModal
					martian={selectedMartianForWithdrawal}
					onConfirm={confirmWithdrawal}
					onCancel={cancelWithdrawal}
					withdrawalProcessStarted={withdrawalProcessStarted}
				/>
			)}
		</>
	);
};
