import Typography from '../../Typography';

const GameRules = () => {
	return (
		<div className="w-full bg-black pt-2 pb-12">
			<div className="m-auto mt-10 w-11/12 md:w-3/5 text-center">
				<Typography variant="h1">Rules of the game</Typography>
				<div className="mb-40 mt-4 mx-4 p-4 text-white text-sm border-2 border-white rounded-2xl lg:mb-0 lg:mx-12 lg:p-6">
					<p className="text-left">
						If you own a Martian, you can challenge another Martian from a
						different crypto-faction. The cryptocurrency that will grow the
						most* (percentage wise) in the next 24 hours will give its Martian
						the victory.
					</p>
					<p className="mt-4 text-left">e.g.</p>
					<p className="text-left">
						1. Martian #345 (DOGE) challenges Martian #6194 (BTC)
					</p>
					<p className="text-left">
						2. Martian #6194 accepts on December 12, 2021 at 4:23 pm UTC
					</p>
					<p className="text-left">
						3. After 24h, DOGE went down by 12.3% while BTC went down by 7.3%.
						BTC grew the most (or shrunk the least).
					</p>
					<p className="text-left">
						4. Martian #6194 (BTC) is therefore the winner
					</p>
					<p className="mt-4 text-left">
						The winner will receive the NFT of the loser. In the above example,
						the owner of Martian #6194 will receive the NFT Martian #345.
					</p>
				</div>
			</div>
		</div>
	);
};

export default GameRules;
