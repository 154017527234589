import Button from 'components/Button';

import { replaceAssetsUrl } from '../../../utils';
import { ContentWrapper } from '../../ContentWrapper';
import Img from '../../Img';
import GameRules from './GameRules';

const ConfirmMartianSelection = ({ selectedMartian, onConfirm, onConfirmChallengeFriend }) => {
	return (
		<div className="flex-col w-full h-full">
			<ContentWrapper
				className="m-auto"
				title="Battle"
				heading={<span className="text-gray-400">Against <span className="text-yellow-400">{selectedMartian.rarity.match_min_with}%</span> + rarity martians</span>}
			>
				<div className="m-auto pt-4">
					<Img
						className="p-4"
						src={replaceAssetsUrl(selectedMartian?.assets?.small?.face_url)}
						width={200}
						height={200}
						alt=""
					/>
				</div>
				<div className="mb-4 mx-auto w-3/4 md:w-1/2">
					
					<Button small className="w-full uppercase mb-2"  onClick={onConfirmChallengeFriend}>
						Challenge a friend
					</Button>
					<div className="mb-2 text-center">or</div>
					<div className="my-2 w-96 p-2 bg-red-500 text-white rounded">
						<strong>DANGER!!</strong> The Random Battle is a winner takes all death match. 
						You can loose your martian. Please do this at your own risk!
					
					</div>
					
					
					<Button small className="w-full uppercase" ghost onClick={onConfirm}>
						Random match
					</Button>
          <div className="my-4 text-sm text-yellow-400 text-center">Your martian #{selectedMartian.id} will be at risk</div>
				</div>
			</ContentWrapper>
			<GameRules />
		</div>
	);
};

export default ConfirmMartianSelection;
