export default function Loader({ className }: { className?: string }) {
	return (
		<span
			className={`inline-flex rounded-full animate-spin ${className || ""}`}
		>
			<style jsx>{`
				span {
					width: 1em;
					height: 1em;
					border: 0.15em solid currentColor;
					border-left-color: transparent;
				}
			`}</style>
		</span>
	);
}
