import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { ContentWrapper, SubmitButton } from '../../ContentWrapper';
import Loader from '../../Loader';
import Typography from '../../Typography';

export const SelectChallenger = ({ onSelectChallengerUsername }) => {
  const { register, getValues } = useForm();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const submitForm = (isFriendly) => {
    const username = getValues('username');
    onSelectChallengerUsername(username, isFriendly)
  }
  
  return (
    <ContentWrapper
      title="Your Challenger?"
      heading="Enter the username/handle of your challenger"
    >
      <form className="flex flex-col mt-10 md:mx-auto md:w-3/4" onSubmit={(e) => e.preventDefault()}>
        <div className="relative block text-center lg:text-lg text-base tracking-wider lg:mx-20 mx-6">
          <label className="font-bold">
            Username
            <input 
              type="text"
              autoFocus={true}
              // maxLength={10}
              className="block my-2 w-full text-blueGray-900 bg-white focus:bg-white focus:border-gray-500 border-transparent rounded-full focus:ring-0 lg:py-4 py-4 text-center"
              placeholder="e.g ZorgDaDestructor"
              required
              {...register("username")}
            />
          </label>
          {error && 
            <Typography variant="sp" className="ml-2 mt-2 text-center text-red-200">
              {error}
            </Typography>
          }
          <SubmitButton small width='w-full' label={loading ? <Loader className="block" /> : "Send request"} onClick={() => submitForm(false)}/>

          <SubmitButton className="mt-0 mb-4" small outline width='w-full' label={loading ? <Loader className="block" /> : "Send friendly request"} onClick={() => submitForm(true)}/>

          <p className="text-xs">
            You won't risk to lose your martian when you send a friendly request
          </p>
        </div>
      </form>
    </ContentWrapper>
  )
}