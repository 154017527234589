import { ContentWrapper, SubmitButton } from 'components';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { sendEmail } from 'state';

import useWeb3 from '../../../hooks/useWeb3';
import { useGlobalState } from '../../../state';
import { UserType } from '../../../state/reducers/user';
import Loader from '../../Loader';
import Typography from '../../Typography';

type EmailInputProps = {
	user: UserType;
};

export const EmailDialog: FC<EmailInputProps> = ({ user }) => {
	const { register, handleSubmit } = useForm();
	const [_, dispatch] = useGlobalState();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const { createSignature } = useWeb3({ readOnly: true });

	const handleFormSubmit = async ({ email }) => {
		if (user.address) {
			const signature = await createSignature(user.address, email);
			setLoading(true);
			sendEmail(user.address, email, user.nickname, signature)
				.then(dispatch)
				.then(() => {
					setLoading(false);
				})
				.catch((error) => {
					setError(error.toString());
					setLoading(false);
				});
		}
	};

	return (
		<ContentWrapper
			title="Reach You Through Space"
			heading="We need to notify you about your Battles. No spam. No Marketing"
		>
			<form
				className="flex flex-col mt-10 lg:mt-10 lg:mx-auto lg:w-3/4"
				onSubmit={handleSubmit(handleFormSubmit)}
			>
				<div className="relative block mx-6 text-center text-base tracking-wider lg:mx-20 lg:text-lg">
					<label className="">
						Email
						<input
							type="email"
							autoFocus={true}
							className="block my-2 py-4 w-full text-center text-blueGray-900 bg-gray-100 focus:bg-white focus:border-gray-500 border-transparent rounded-full focus:ring-0 lg:py-5"
							placeholder="e.g zorgdadestructor@mail.com"
							required
							{...register("email")}
						/>
					</label>
					{error && (
						<Typography
							variant="sp"
							className="ml-2 mt-2 text-center text-red-200"
						>
							{error}
						</Typography>
					)}
					<SubmitButton
						width="w-full"
						label={loading ? <Loader className="block" /> : "Next"}
					/>
				</div>
			</form>
		</ContentWrapper>
	);
};
