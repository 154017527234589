import { useEffect, useState } from 'react';

function useTimer(
	options?: { hideHours?: boolean; reset?: boolean }
) {
	const [time, setTime] = useState(0);

	useEffect(() => {
		let interval;
		(function run(current) {
			interval = setInterval(
				() =>
					setTime((current: number) => {
						return current + 1;
					}),
				1000
			);
		})();
		return () => {
			clearInterval(interval);
		};
	}, [options?.reset]);

	return countdown(time || 0, options?.hideHours);
}

function countdown(seconds: number, hideHours?: boolean) {
	const timer = seconds;
	if (seconds <= 0) {
		return {
			display: "00:00:00",
			countdown,
		};
	}
	const days = Math.floor(seconds / (3600 * 24));
	seconds -= days * (3600 * 24);
	const hours = Math.floor(seconds / 3600);
	seconds = seconds % 3600;
	const minutes = Math.floor(seconds / 60);
	seconds = Math.floor(seconds % 60);

	const display = {
		days: days <= 0 ? "" : `${days} day${days > 1 ? "s" : ""} `,
		hours: hideHours ? "" : `${("0" + hours).slice(-2)}:`,
		minutes: `${("0" + minutes).slice(-2)}:`,
		seconds: `${("0" + seconds).slice(-2)}`,
	};

	return {
		display: `${display.days}${display.hours}${display.minutes}${display.seconds}`,
		timer,
	};
}

export default useTimer;
