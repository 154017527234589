import { FC, useEffect, useState } from 'react';
import { SignupStep } from 'state';
import { UserType } from 'state/reducers/user';

import { EmailDialog, UsernameDialog, VerifyEmailDialog } from './signup';

type RequiredProps = {
  user: UserType,
}

export const SignupFlow: FC<RequiredProps> = ({ user }) => {
  const { nextStep, isVerified, address } = user;

  return (
    <>
      {nextStep === SignupStep.set_username && 
        <UsernameDialog
          address={address}
        />
      }

      {nextStep === SignupStep.set_email && 
        <EmailDialog user={user} />
      }

      {nextStep === SignupStep.wait_email_confirmation && <VerifyEmailDialog />}
    </>
  )
}
