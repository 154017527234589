import { ContentWrapper } from 'components';
import Typography from 'components/Typography';
import { FC } from 'react';

type VerifyEmailType = { }

export const VerifyEmailDialog: FC<VerifyEmailType> = ({  }) => 
  <ContentWrapper
    title="Verify Your Email"
    heading="We sent you an email. Please click on it to continue."
    icon="verifyEmailIcon"
  >
    <Typography
      variant="h5"
      className="lg:px-44 pt-12 pb-8 text-center"
    >
      Don't worry, we'll wait.
    </Typography>
  </ContentWrapper>