import Button from 'components/Button';
import Typography from 'components/Typography';
import useTimer from 'hooks/useTimer';

import { replaceAssetsUrl } from '../../../utils';
import { ContentWrapper } from '../../ContentWrapper';
import Img from '../../Img';
import GameRules from './GameRules';

const MatchingRandomMartian = ({
	onCancel,
	currentUser,
	battle,
	onConfirmBattle,
}) => {
	const { display, timer } = useTimer();

	if (battle) {
		const opponentGamer =
			battle.gamers[0].address === currentUser.address
				? battle.gamers[1]
				: battle.gamers[0];

		return (
			<div className="flex-col m-auto w-full">
				<ContentWrapper
					className="mx-auto pb-10"
					title={`#${opponentGamer.martian.id}`}
					heading={`Team #${opponentGamer.martian.currency.name}  #${opponentGamer.martian.currency.symbol}`}
				>
					<Img
						alt="martian waiting"
						width={150}
						height={150}
						className="flex flex-shrink-0 mt-8"
						src={replaceAssetsUrl(opponentGamer.martian?.assets?.small?.face_url)}
					/>
					<Typography variant="h3" className="text-center mt-6 text-yellow-400">
						{opponentGamer.username}
					</Typography>
					<div className="w-1/3 mx-auto mt-10">
						<Button
							small
							className="mb-4 w-full"
							ghost
							onClick={onConfirmBattle}
						>
							Let's fight
						</Button>
					</div>
				</ContentWrapper>
				<GameRules />
			</div>
		);
	}

	return (
		<div className="flex-col m-auto w-full">
			<ContentWrapper
				className="mx-auto pb-10"
				title="Matching you up"
				heading=""
			>
				<Img
					alt="martian waiting"
					width={150}
					height={150}
					className="flex flex-shrink-0 mt-4"
					src={`/static/images/matching.gif`}
				/>
				<Typography variant="bigTitle" className="text-center mt-8 md:mt-2">
					{display}
				</Typography>
				<p className="text-md text-center text-gray-400 mt-4">
					You'll be matched with the first available challenger
				</p>
				<a href="#" className="mx-auto my-10 text-yellow-400" onClick={onCancel}>
					Cancel battle
				</a>
			</ContentWrapper>
			<GameRules />
		</div>
	);
};

export default MatchingRandomMartian;
