import { ContentWrapper } from "components"
import Button from "components/Button"
import { FC, SyntheticEvent } from "react"
import Typography from '../../Typography';

type RegisterSectionProps = {
  i18n: {
    title: string;
    cta_connect: string;
  }
  onClick: (e: SyntheticEvent) => void;
}
  
export const RegisterWallet: FC<RegisterSectionProps> = ({ i18n, onClick }) => 
  <div className="z-10 container h-full m-auto text-center">
    <Typography component="h1" variant="bigTitle" className="text-center capitalize mt-60">
      {i18n.title}
    </Typography>
    <Button className="mt-20 px-16 py-4 lg:w-auto w-4/5" onClick={onClick}>
      {i18n.cta_connect}
    </Button>
  </div>
