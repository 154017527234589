import Button from 'components/Button';
import Link from 'components/Link';
import Logo from 'components/Logo';
import useNoScroll from 'hooks/useNoScroll';
import useWeb3 from 'hooks/useWeb3';
import useWindowSize from 'hooks/useWindowSize';
import Router, { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useGlobalState } from 'state';
import { log, LOG_ACTIONS } from 'utils';

export default function BattlefieldHeader() {
	const [showBurger, setshowBurger] = useState(false);
	const [{ web3, user }] = useGlobalState();
	const { loadModal } = useWeb3({ readOnly: true });
	const { accounts } = web3;
	const router = useRouter();
	const { width } = useWindowSize();
	const links = [
		{ href: "/mint", label: "Mint" },
		{ href: "/explore", label: "Explore" },
		{ href: "/trade", label: "Trade" },
	];
	useNoScroll(showBurger && width <= 768);

	useEffect(() => {
		if (accounts?.current) {
			log(LOG_ACTIONS.MARTIANS_ACCOUNT_CONNECTED, undefined, undefined, {
				owner_address: accounts?.current,
			});
		}
	}, [accounts?.current]);

	useEffect(() => {
		const routeChangeComplete = () => {
			setshowBurger(false);
		};
		Router.events.on("routeChangeComplete", routeChangeComplete);
		return () => {
			Router.events.off("routeChangeComplete", routeChangeComplete);
		};
	}, []);

	return (
		<header className="sticky z-50 top-0 h-16">
			<div className="lg:hidden">
				{/** Menu Overlay */}
				<div
					className={`fixed block w-full transform inset-0 transition-opacity bg-black bg-opacity-90 pointer-events-none ${
						showBurger ? "opacity-90" : "opacity-0"
					}`}
				/>
				<div className="absolute left-0 right-0 top-0 pointer-events-none">

					{/** Main menu */}
					<div className="flex items-center justify-center pointer-events-auto p-4 bg-black lg:bg-transparent">
						<Link href="http://martians.marsgenesis.com">
							<Logo component="a" type="mrtn" />
						</Link>
					</div>
					{/** Burger Menu! Content */}
					<div
						className={`flex flex-col py-2 overflow-auto transform transition-all origin-top ${
							showBurger
								? "opacity-1 scale-100 delay-100 duration-150 pointer-events-auto"
								: "opacity-0 scale-90 duration-50"
						}`}
						style={{
							animationDelay: showBurger ? "300ms" : "300ms",
							maxHeight: "calc(100vh - 72px)",
						}}
					>

						<a
							className="relative block mt-4 pr-5 py-4"
							href="https://marsgenesis.com"
						>
							<Logo component="div" type="mg" />
							<svg
								className="absolute right-5 top-1/2 w-2 transform -translate-y-1/2"
								viewBox="0 0 22 40"
								fill="none"
							>
								<path
									d="M2 38L20 20L2 2"
									stroke="white"
									strokeWidth="4"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</a>
					</div>
				</div>
			</div>
			<div className="hidden px-4 h-full lg:flex lg:justify-between 2xl:container 2xl:mx-auto">
				<Link href="http://martians.marsgenesis.com">
					<Logo className="relative z-50 p-12" component="a" type="mrtn" />
				</Link>
				<div className="absolute inset-0 flex justify-center p-2 w-full space-x-2">
				</div>
				<div className="relative z-50 flex items-center space-x-4 p-10">
					{(user.step && user.step !== 'signup' && router.pathname !== '/battlefield') && (
						<Button small onClick={() => router.push('/battlefield')}>
							Go to the battlefield
						</Button>
					)}
				</div>
			</div>
			{/* <aside className="fixed z-10 bottom-4 right-4">
				<a
					href="https://discord.gg/martians"
					target="_blank"
					rel="noopener noreferrer"
					className="hover:shadow-12dp shadow-6dp relative z-20 flex items-center justify-center p-2 w-14 h-14 text-blueGray-900 bg-white rounded-full shadow-lg"
				>
					<Discord />
					<span className="sr-only">Join our discord</span>
				</a>
			</aside> */}
		</header>
	);
}
