import { useEffect, useState } from 'react';

function useCountdown(
	remaining: number,
	options?: { hideHours?: boolean; hideDays?: boolean; reset?: boolean }
) {
	const [timeLeft, setTimeLeft] = useState<undefined | number>();

	useEffect(() => {
		let timeout;
		(function run(remaining) {
			timeout = setTimeout(
				() =>
					setTimeLeft((current: number) => {
						if (typeof current === "undefined") {
							run(remaining);
							return remaining;
						} else if (current <= 0) {
							clearTimeout(timeout);
							return undefined;
						} else {
							run(remaining);
							return current - 1;
						}
					}),
				1000
			);
		})(remaining);
		return () => {
			clearInterval(timeout);
		};
	}, [options?.reset, remaining]);

	return countdown(timeLeft || 0, options?.hideHours, options?.hideDays);
}

function countdown(seconds: number, hideHours?: boolean, hideDays?: boolean) {
	const countdown = seconds;
	if (seconds <= 0) {
		return {
			display: "00:00:00",
			countdown,
		};
	}
	const days = Math.floor(seconds / (3600 * 24));
	seconds -= days * (3600 * 24);
	const hours = Math.floor(seconds / 3600);
	seconds = seconds % 3600;
	const minutes = Math.floor(seconds / 60);
	seconds = Math.floor(seconds % 60);

	const display = {
		days: days <= 0 ? "" : `${days} day${days > 1 ? "s" : ""} `,
		hours: hideHours ? "" : `${("0" + hours).slice(-2)}:`,
		minutes: `${("0" + minutes).slice(-2)}:`,
		seconds: `${("0" + seconds).slice(-2)}`,
	};

	if (hideDays) {
		display.days = '';
	}

	return {
		display: `${display.days}${display.hours}${display.minutes}${display.seconds}`,
		countdown,
	};
}

export default useCountdown;
