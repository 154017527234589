import { ContentWrapper } from 'components';
import Loader from 'components/Loader';

import { replaceAssetsUrl } from '../../../utils';
import { SubmitButton } from '../../ContentWrapper';
import Img from '../../Img';

const WithdrawMartianModal = ({
	martian,
	onConfirm,
	onCancel,
	withdrawalProcessStarted,
}) => {
	return (
		<>
			<div
				className="absolute z-10 m-auto pt-10 w-full h-full bg-black bg-opacity-50"
				onClick={onCancel}
			></div>
			<div className="absolute z-20 m-auto w-full">
				<ContentWrapper
					title={withdrawalProcessStarted ? `Withdrawing...` : `Withdraw #${martian?.id}?`}
					heading={withdrawalProcessStarted ? '' : `Are you sure you want to withdraw #${martian?.id}?`}
					className="mx-auto w-full"
				>
					<Img
						className="p-4"
						src={replaceAssetsUrl(martian?.assets?.small?.face_url)}
						width={180}
						height={180}
						alt=""
					/>

					{!withdrawalProcessStarted && (
						<div className="mx-auto p-1 w-2/3 text-center text-xs border border-white rounded-full">
							You will need to pay for the gas to transfer your martian.
						</div>
					)}
					<div className="m-auto w-2/3 md:w-1/2">
						{withdrawalProcessStarted ? (
							<>
								<div className="mt-4 text-center mx-auto" ><Loader />
								</div>
								<div className="mt-4 text-center text-white">
									Please confirm the transaction in your wallet to pay for
									withdrawal fees.
								</div>
							</>
						) : (
							<SubmitButton
								small
								label="Yes please!"
								className="mb-4 w-full"
								onClick={onConfirm}
							/>
						)}
					</div>
					<div className="mb-20 mt-4 mx-auto w-1/2 text-center">
						{!withdrawalProcessStarted && (
							<a href="#" onClick={onCancel}>
								I changed my mind
							</a>
						)}
					</div>
				</ContentWrapper>
			</div>
		</>
	);
};

export default WithdrawMartianModal;
